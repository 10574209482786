import { Route, Routes } from "react-router-dom";
import { Transfer } from "./pages/Transfer";
import { Gemini } from "./pages/Gemini";
import { Search } from "./pages/Search";
import Advertise from "./pages/Advertise";
// import useSwipe from "./hooks/useSwipe";

function App() {

  // useSwipe()

  return (
    <Routes>
      <Route element={<Transfer />} path="/" />
      <Route element={<Gemini />} path="/gemini" />
      <Route element={<Search />} path="/search" />
      <Route element={<Advertise />} path="/advertising" />
    </Routes>
  );
}

export default App;
