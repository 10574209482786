import React from 'react'

const FileThumbnail = ({ file,theme,onRemove}) => {

   const handleClick = () =>{
        onRemove(file)
   }
    
    return (
        <div className='file-thumbnail'>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox='0 0 24 24' >
                <g transform="translate(0 -1028.4)">
                    <g opacity='0.7'>
                        <path d="m5 1030.4c-1.1046 0-2 0.9-2 2v8 4 6c0 1.1 0.8954 2 2 2h14c1.105 0 2-0.9 2-2v-6-4-4l-6-6h-10z" fill={theme} />
                    </g>
                    <g opacity='0.4'>
                        <path d="m5 1029.4c-1.1046 0-2 0.9-2 2v8 4 6c0 1.1 0.8954 2 2 2h14c1.105 0 2-0.9 2-2v-6-4-4l-6-6h-10z" fill={theme} />
                    </g>
                    <path d="m21 1035.4-6-6v4c0 1.1 0.895 2 2 2h4z" fill={theme} />
                </g>
            </svg>
            <div className='file-extension'>
                <strong>{file.name.slice((file.name.lastIndexOf(".") - 1 >>> 0) + 2)}</strong>
            </div>
            <button className='btn rm-btn p-0' type='button' onClick={handleClick}>
                <svg width="8" height="8" viewBox="0 0 44 55" fill="#000" xmlns="http://www.w3.org/2000/svg">
                    <path d="M32.738 54.5195L21.378 34.5995L10.578 54.5195H0.497966L16.418 26.2795L1.37797 0.119531H12.258L22.738 18.3595L32.578 0.119531H42.658L27.698 26.7595L43.618 54.5195H32.738Z" fill="#000" />
                </svg>
            </button>
        </div>
    )
}

export default FileThumbnail