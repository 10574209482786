import React, { useCallback } from 'react'
import useLayout from '../../hooks/useLayout'
import useApp from '../../hooks/useApp'
import axios from 'axios'

const Footer = ({onRemove}) => {

  const { store, setShowLogin } = useApp()
  const { layout, setLayout } = useLayout()

  const handleThemeChange = useCallback(async e => {
    if (e.target.nodeName === 'BUTTON') {
      if (store.token) {
        const t = e.target.getAttribute('data-color')
        try {
          const payload = new FormData()
          payload.append('type', t)
          let res = await axios.post('/user/update-theme/', payload, {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Token ${store.token}`
            }
          })
          if (res.status === 200) {
            let p = window.localStorage.getItem('user')
                    let u = JSON.parse(p)
                    u[1].theme_type = t;
                    window.localStorage.user = JSON.stringify(u)
            setLayout(p => ({
              ...p,
              themeColor: t
            }))
          }
        } catch (e) {
          console.log(e)
        }
      } else {
        setShowLogin(true)
      }
    }
  }, [store.token])

  return (
    <footer className='footer'>
      <div className='f-content lg'>
        <div className='left'>
          <img src={`${process.env.PUBLIC_URL}/images/footer-left.png`} alt='bg-img' />
        </div>
        <div className='center'>
          <div className='center-content'>
            <p className='m-0'>
              Support marine or ocean conservation by using <br /> our simple tools that improve your life.
            </p>
          </div>
          <button className='btn btn-dark footer-close-btn p-0' type='button' onClick={onRemove}>
                <svg width="8" height="8" viewBox="0 0 44 55" fill="#fff" xmlns="http://www.w3.org/2000/svg">
                    <path d="M32.738 54.5195L21.378 34.5995L10.578 54.5195H0.497966L16.418 26.2795L1.37797 0.119531H12.258L22.738 18.3595L32.578 0.119531H42.658L27.698 26.7595L43.618 54.5195H32.738Z" fill="#fff" />
                </svg>
          </button>
        </div>
        <div className='right'>
          <img src={`${process.env.PUBLIC_URL}/images/footer-right.png`} alt='bg-img' />
        </div>
      </div>
      <div className='f-content sm'>
        <div className='f-thems-btns-wrapper'> {/** onClick={handleThemeChange} */}
          <button className={`f-theme-btn ocean ${layout.themeColor === 'oceans' ? 'border-0' : 'border-top'}`} data-color="oceans">OCEANS</button>
          <button className={`f-theme-btn both ${layout.themeColor === 'both' ? 'border-top-0' : 'border-top'}`} data-color="both">BOTH</button>
          <button className={`f-theme-btn rainforests ${layout.themeColor === 'rainforests' ? 'border-0' : 'border-top'}`} data-color="rainforests">RAINFORESTS</button>
        </div>
      </div>
    </footer>
  )
}

export default Footer