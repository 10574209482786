import axios from 'axios';
import { useState } from 'react';
import { Form } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import useApp from '../../hooks/useApp';
import useLayout from '../../hooks/useLayout';


const SignInModal = ({ show, onClose, onSignup, onReset }) => {

    const [error, setError] = useState(null)
    const [success, setSuccess] = useState(false)
    const { setStore, setConnectionError } = useApp()
    const { setLayout } = useLayout()

    const handleClose = () => {
        onClose()
        setError(null)
        setSuccess(false)
    }

    const validate = payload => {
        let err = null
        for (const [key, value] of payload.entries()) {
            if (key === 'email') {
                if (!value) {
                    err = 'Plase enter a email address!'
                    break;
                } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
                    err = 'Plase enter a valid email address!'
                    break;
                }
            } else if (key === 'password') {
                if (!value) {
                    err = 'Plase enter a password!'
                    break;
                }
            }
        }
        setError(err)
        return err ? false : true
    }

    const handleSubmit = async e => {
        e.preventDefault()
        const payload = new FormData(e.target)
        if (validate(payload)) {
            try {
                let res = await axios.post('/user/login/', payload, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                if (res.status === 200) {
                    const { token, user } = res.data
                    e.target.reset()
                    setSuccess(true)
                    setStore(p=>({
                        ...p,
                        token ,
                        user,
                        isLogin : true
                    }))
                    setLayout(p=>({
                        ...p,
                        themeColor : user.theme_type 
                    }))
                    window.localStorage.setItem('user',JSON.stringify([token, user]))
                    setTimeout(()=>{
                        handleClose()
                    },3000)
                }
            } catch (e) {
                console.log(e)
                const { response } = e
                if(response){
                    setError(response.data.error)
                }else{
                    if(window.navigator.onLine){
                        setConnectionError('Something went wrong')
                      }else{
                        setConnectionError("No Internet Connection")
                      }
                }
            }
        }
    }

    return (
        <Modal
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop='static'
            dialogClassName='modal-dialog modal-dialog-centered custom-modal-dailog'
            contentClassName='custom-modal-dailog-content border-4 border-light bg-black text-white'
            show={show}
            onHide={handleClose}
        >
            <Modal.Header className='border-bottom border-4 position-relative'>
                <Modal.Title className='text-center w-100'>
                    Sign In
                </Modal.Title>
                <Button onClick={handleClose} className='btn-close d-flex btn-black align-items-center justify-content-center bg-transparent' style={{ backgroundImage: 'none' }}>
                    <img src={`${process.env.PUBLIC_URL}/images/X-ic.svg`} width={30} alt='icon' />
                </Button>
            </Modal.Header>
            <Modal.Body className='pt-4'>
            {success ? <div className={`m-0 bg-success text-white px-3 py-1 d-flex align-items-center error top`}>
            <span className='text-white ms-2'>Login Successfully !</span>
          </div> : null }
                <div className={`m-0 bg-danger text-white px-3 py-1 d-flex align-items-center error top ${error ? 'd-block' : 'd-none'}`}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" stroke='#fff' fill="#fff" className="bi bi-exclamation-circle" viewBox="0 0 16 16">
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                        <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0M7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0z" />
                    </svg>
                    <span className='text-white ms-2'>{error}</span>
                </div>
                <Form className='text-center mt-2' onSubmit={handleSubmit}>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Email Address</Form.Label>
                        <Form.Control className='text-center' type="email" name='email' />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Password</Form.Label>
                        <Form.Control className='text-center' type="password" name='password' />
                    </Form.Group>
                    <div className='mb-4 text-end'>
                    <button type='button' onClick={onReset} className='bg-transparent border-0 text-white'>Forgot Password ?</button>
                    </div>
                    <div>
                        <Button className='w-50 btn-dark' type='submit'>Submit</Button>
                    </div>
                    <div>
                        <p className='mt-4 mb-0'>Don’t have an account? <button type='button' onClick={onSignup} className='bg-transparent border-0 text-white'> <strong>Sign Up</strong></button></p>
                    </div>
                </Form>

            </Modal.Body>
        </Modal>
    );
}

export default SignInModal

