import axios from 'axios';
// import { useState } from 'react';
// import { Form } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import useApp from '../../hooks/useApp';
import { useCallback } from 'react';
import useLayout from '../../hooks/useLayout';


const ThemeModal = ({ show, onClose, onSignup, onSignin }) => {

    // const [error, setError] = useState(null)
    // const [success, setSuccess] = useState(false)
    const { layout, setLayout } = useLayout()
    const { store, setShowLogin, setConnectionError } = useApp()

    const isSelected = useCallback(v => v === layout.themeColor, [layout])

    const handleClose = () => {
        onClose()
    }


    const handleChange = useCallback(async e => {
        if (store.token) {
            try {
                const payload = new FormData()
                payload.append('type', e.target.value)
                let res = await axios.post('/user/update-theme/', payload, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Token ${store.token}`
                    }
                })
                if (res.status === 200) {
                    let p = window.localStorage.getItem('user')
                    let u = JSON.parse(p)
                    u[1].theme_type = e.target.value;
                    window.localStorage.user = JSON.stringify(u)
                    setLayout(p => ({
                        ...p,
                        themeColor: e.target.value
                    }))
                }
            } catch (e) {
                const { response } = e
                if (response) {

                } else {
                    if (window.navigator.onLine) {
                        setConnectionError('Something went wrong')
                    } else {
                        setConnectionError("No Internet Connection")
                    }
                }
            }
        } else {
            onClose()
            setShowLogin(true)
        }
    }, [store.token,setConnectionError,setShowLogin,onClose,setLayout])

    return (
        <Modal
            aria-labelledby="contained-modal-title-vcenter"
            centered
            size='lg'
            backdrop='static'
            dialogClassName='modal-dialog modal-dialog-centered custom-modal-dailog'
            contentClassName='custom-modal-dailog-content theme-modal-content border-4 border-light bg-black text-white'
            show={show}
            onHide={handleClose}
        >
            <Modal.Header className='border-bottom border-4 position-relative'>
                <Modal.Title className='text-center w-100'>
                    Choose an option to protect
                </Modal.Title>
                <Button onClick={handleClose} className='btn-close d-flex btn-black align-items-center justify-content-center bg-transparent' style={{ backgroundImage: 'none' }}>
                    <img src={`${process.env.PUBLIC_URL}/images/X-ic.svg`} width={30} alt='icon'  />
                </Button>
            </Modal.Header>
            <Modal.Body className='p-0'>
                <div className='theme-options-wrapper'>
                    <div className="theme-opt-container ocean">
                        <img src={`${process.env.PUBLIC_URL}/images/ocean-option.png`} className='d-none d-lg-block' alt='bg_img' />
                        <img src={`${process.env.PUBLIC_URL}/images/ocean-option-sm.png`} className='d-block d-lg-none' alt='bg_img' />
                        <div className='opt-wrapper'>
                            <input className='text-center' id='oc' type="radio" value='oceans' name='theme' checked={isSelected('oceans')} onChange={handleChange} />
                            <label className='d-block text-center' htmlFor='oc'>Oceans</label>
                        </div>
                    </div>
                    <div className="theme-opt-container both">
                        <img src={`${process.env.PUBLIC_URL}/images/both-option.png`} className='d-none d-lg-block'alt='bg_img' />
                        <img src={`${process.env.PUBLIC_URL}/images/both-option-sm.png`} className='d-block d-lg-none' alt='bg_img' />
                        <div className='opt-wrapper'>
                            <input className='text-center' id='bt' type="radio" value='both' name='theme' checked={isSelected('both')} onChange={handleChange} />
                            <label className='d-block text-center' htmlFor='bt'>Both</label>
                        </div>
                    </div>
                    <div className="theme-opt-container rainforests">
                        <img src={`${process.env.PUBLIC_URL}/images/rainforest-option.png`} className='d-none d-lg-block' alt='bg_img' />
                        <div className='opt-wrapper'>
                            <input className='text-center' type="radio" id='rf' value='rainforests' name='theme' checked={isSelected('rainforests')} onChange={handleChange} />
                            <label className='d-block text-center' htmlFor='rf'>Rainforests</label>
                        </div>
                    </div>
                </div>
                <div>
                    <p className='my-4 fs-5 text-center d-none d-lg-block'>Do you have an account? <button type='button' onClick={onSignin} className='bg-transparent border-0 text-white'> <strong>Sign In</strong></button> or <button type='button' onClick={onSignup} className='bg-transparent border-0 text-white'> <strong>Sign Up</strong></button></p>
                </div>

            </Modal.Body>
        </Modal>
    );
}

export default ThemeModal

