import React, { useEffect, useState } from 'react'
// import Footer from './components/footer/Footer'
import Header from './components/header/Header'
import Footer from './components/cfooter/Footer'
import useLayout from './hooks/useLayout'
import SignUpModal from './components/modal/SignUpModal'
import SignInModal from './components/modal/SignInModal'
import { useLocation, useNavigate } from 'react-router-dom'
import ResetModal from './components/modal/ResetModal'
import ThemeModal from './components/modal/ThemeModal'
import useApp from './hooks/useApp'
import { useSwipeable } from 'react-swipeable'



const Layout = ({ children }) => {

    const { layout, setLayout } = useLayout()
    const { showLogin, setShowLogin, connectionError, setConnectionError } = useApp()
    const { pathname } = useLocation()
    const navigate  = useNavigate()
    const [showRegister, setShowRegister] = useState(false)
    const [showReset, setShowReset] = useState(false)
    const [showTheme, setShowTheme] = useState(false)
    const [isAddPage, setIsAddPage] = useState(false)
    const [isFooter, setIsFooter] = useState(true)

    useEffect(() => {
        if (pathname !== '/') {
            if (pathname === '/advertising') {
                setLayout(p => ({
                    ...p,
                    fixHeight: false
                }))
            } else {
                setLayout(p => ({
                    ...p,
                    fixHeight: true
                }))
            }
        } else {
            setLayout(p => ({
                ...p,
                fixHeight: false
            }))

        }
        if (pathname === '/advertising') {
            setIsAddPage(true)
        } else {
            setIsAddPage(false)
        }
    }, [pathname, setLayout])

    useEffect(() => {
        const q = new URLSearchParams(window.location.search);
        if (q.get('type') === 'reset') {
            setShowReset(true)
        }
        window.appUrls = ['/', '/gemini', '/search', '/advertising']
        window.appCurrentUrl = window.appCurrentUrl || 0
        window.appPrevUrl = window.appCurrentUrl > 0 ? window.appCurrentUrl - 1 : 0
        window.appNextUrl = window.appCurrentUrl < window.appUrls.length - 1 ? window.appCurrentUrl + 1 : window.appCurrentUrl
    }, [])

    const handleLoginClose = () => {
        setShowLogin(false)
    }
    const handleRegisterClose = () => {
        setShowRegister(false)
    }
    const handleResetClose = () => {
        setShowReset(false)
    }
    const handleThemeClose = () => {
        setShowTheme(false)
    }
    const openSignin = () => {
        handleRegisterClose()
        setShowLogin(true)
    }
    const openSignup = () => {
        handleLoginClose()
        setShowRegister(true)
    }
    const openReset = () => {
        handleLoginClose()
        setShowReset(true)
    }
    const openSignupReset = () => {
        handleResetClose()
        setShowRegister(true)
    }
    const onAccount = () => {
        // setShowLogin(true)
    }
    const openSigninT = () => {
        handleThemeClose()
        setShowLogin(true)
    }
    const openSignupT = () => {
        handleThemeClose()
        setShowRegister(true)
    }
    const handleCErrorClose = () => {
        setConnectionError(null)
    }

    const handleRemoveFooter = () => {
        setIsFooter(false)
    }

    const swipeLeftHandler = () => {
        window.appPrevUrl = window.appCurrentUrl > 0 ? window.appCurrentUrl - 1 : 0
        window.appCurrentUrl = window.appNextUrl
        window.appNextUrl = window.appCurrentUrl < window.appUrls.length - 1 ? window.appCurrentUrl + 1 : window.appCurrentUrl
        navigate(window.appUrls[window.appCurrentUrl])
    }
    const swipeRightHandler = () => {
        window.appPrevUrl = window.appCurrentUrl > 0 ? window.appCurrentUrl - 1 : 0
        window.appCurrentUrl = window.appPrevUrl
        window.appNextUrl = window.appCurrentUrl < window.appUrls.length - 1 ? window.appCurrentUrl + 1 : window.appCurrentUrl
        navigate(window.appUrls[window.appCurrentUrl])
    }

    const handlers = useSwipeable({
        onSwipedLeft: swipeLeftHandler,
        onSwipedRight: swipeRightHandler,
        delta : 180,
        preventDefaultTouchmoveEvent: true,
        preventScrollOnSwipe: true,
        trackMouse: false
    });

    return (
        <div {...handlers} className={`wrapper ${layout.fixHeight ? 'fix' : ''} ${!isFooter ? 'mpb-0' : ''}`} data-theme={layout.themeColor}>
            <Header onAccount={onAccount} />
            <div className={`inner ${isAddPage ? 'd-flex align-items-center' : ''}`}>
                {/* <div className='bg'>
                        <img src={`${process.env.PUBLIC_URL}/images/right.png`} />
                    </div>
                    <div className='bg'>
                        <img src={`${process.env.PUBLIC_URL}/images/left.png`} />
        </div>*/}
                <div className={`bg ${!layout.backgroundImages ? 'd-none' : ''}`}>

                    <div className='left'>
                        <div className='l-top-side' style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg-top-left.png)` }}>
                            {/*<img src={`${process.env.PUBLIC_URL}/images/bg-top-left.png`} />*/}

                        </div>
                        <div className='l-bottom-side d-flex align-items-start position-relative'>

                            <img src={`${process.env.PUBLIC_URL}/images/bg-bottom-left.png`} alt='bg_banner' style={{ position: 'absolute', top: '0', left: '0', width: '100%', height: '100%', objectFit: 'fill' }} />
                            <div className='tag'>
                                <div className='tag-body d-flex align-items-start justify-content-center'>
                                    <p>Protect the</p> &nbsp;
                                    <h3>OCEANS</h3>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className='center-right' style={{ background: `top center / cover no-repeat #252121  url(${process.env.PUBLIC_URL}/images/center-right.png)` }}>
                        <div className='center'>
                            <div className='c-top-side'>
                                {/**<img src={`${process.env.PUBLIC_URL}/images/bg-top-center.png`} alt='bg-img' /> */}
                            </div>
                            <div className='c-bottom-side' style={{ background: '#252121' }}>
                                <img src={`${process.env.PUBLIC_URL}/images/bg-bottom-center.png`} alt='bg-img' />
                            </div>
                        </div>
                        <div className='right'>
                            <div className='r-top-side d-flex align-items-end'> {/**style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg-top-right.png)` }} */}
                                {/*                            <img src={`${process.env.PUBLIC_URL}/images/bg-top-right.png`} />*/}
                                <div className='tag'>
                                    <div className='tag-body d-flex align-items-baseline justify-content-center'>
                                        <p>Protect the</p> &nbsp;
                                        <h3>RAINFORESTS</h3>
                                    </div>
                                </div>
                            </div>
                            <div className='r-bottom-side' style={{ background: '#252121' }}>
                                <img src={`${process.env.PUBLIC_URL}/images/bg-bottom-right.png`} alt='bg-img' />
                            </div>

                        </div>
                    </div>
                </div>

                <div className={`content ${isAddPage ? 'advertise-area' : ''}`}>
                    <div className='left'>

                        {/* <div className='tag'>
                                <div className='tag-body'>
                                    <p>Protect the</p> &nbsp;
                                    <h3>OCEAN</h3>
                                </div>
    </div>*/}
                    </div>
                    <div className='center'>
                        <div className='center-content-wrapper'>
                            {children}
                        </div>
                    </div>
                    <div className='right'>

                        {/*<div className='tag'>
                                <div className='tag-body'>
                                    <p>Protect the</p> &nbsp;
                                    <h3>RAINFOREST</h3>
                                </div>
    </div>*/}
                    </div>
                    {/* <div className='d-lg-none'>
                            <Row>
                                <Col>
                                    <span>OCEAN</span>
                                </Col>
                                <Col>
                                    <span>BOTH</span>
                                </Col>
                                <Col>
                                    <span>RAINFOREST</span>
                                </Col>
                            </Row>
    </div>*/}
                </div>

            </div>
            {isFooter ? <Footer onRemove={handleRemoveFooter} /> : null}
            {connectionError ? <div className='connection-error bg-danger'>

                <p className='m-0 flex-grow-1'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" stroke='#fff' fill="#fff" className="bi bi-exclamation-circle" viewBox="0 0 16 16">
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                        <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0M7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0z" />
                    </svg>
                    <span className='ms-1'>
                        {connectionError}
                    </span>
                </p>
                <button className='btn btn-transparent p-0 d-inline-flex align-items-center' onClick={handleCErrorClose}>
                    <svg width="16" height="16" viewBox="0 0 44 55" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M32.738 54.5195L21.378 34.5995L10.578 54.5195H0.497966L16.418 26.2795L1.37797 0.119531H12.258L22.738 18.3595L32.578 0.119531H42.658L27.698 26.7595L43.618 54.5195H32.738Z" fill="white" />
                    </svg>
                </button>
            </div> : null}
            <SignUpModal show={showRegister} onClose={handleRegisterClose} onSignin={openSignin} />
            <SignInModal show={showLogin} onClose={handleLoginClose} onSignup={openSignup} onReset={openReset} />
            <ResetModal show={showReset} onClose={handleResetClose} onSignup={openSignupReset} />
            <ThemeModal show={showTheme} onClose={handleThemeClose} onSignup={openSignupT} onSignin={openSigninT} />
        </div>
    )
}

export default Layout