import React, { useCallback, useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import useApp from '../../hooks/useApp'
import { initialState } from '../../contexts/AppContext'
import axios from 'axios'
import useLayout from '../../hooks/useLayout'


const Header = ({onAccount}) => {

  const { pathname } = useLocation()
  const { store , setStore, setConnectionError } = useApp()
  const { setLayout } = useLayout()
  const { isLogin } = store
  const [active,setActive] = useState(0)
  const [show,setShow] = useState(false)

  const onLogout = useCallback(async () =>{
    try {
      let res = await axios.get('/user/logout/', {
        headers: {
          'Content-Type': 'application/json',
          'Authorization' : `Token ${store.token}`
        }
      })
      if (res.status === 200) {
        setStore(()=>initialState)
        setLayout(p=>({...p,themeColor:'both'}))
        window.localStorage.removeItem('user')
      }
    } catch (e) {
      const { response } = e
      if (response) {

      } else {
          if (window.navigator.onLine) {
              setConnectionError('Something went wrong')
          } else {
              setConnectionError("No Internet Connection")
          }
      }
    }
  },[store.token,setConnectionError,setStore,setLayout])

  const handleClick = () =>{
    setShow(p=>!p)
  }

  useEffect(()=>{
    if(pathname === '/'){
      setActive(0)
      window.appCurrentUrl = 0
    }else if(pathname === '/gemini'){
      setActive(1)
      window.appCurrentUrl = 1
    }else if(pathname === '/search'){
      setActive(2)
      window.appCurrentUrl = 2
    }else if(pathname === '/advertising'){
      setActive(3)
      window.appCurrentUrl = 3
    }
  },[pathname])

  // useEffect(()=>{
  //   console.log(swipeTo)
  //   if(swipeTo === 'left'){
  //     navigate(-1)
  //   }
  //   if(swipeTo === 'right'){
  //     navigate(2)
  //   }
  // },[swipeTo])

  

  return (
    <div className='header'>
      <div className='mobile-head'>
        <div>
          <img src={`${process.env.PUBLIC_URL}/images/logo.png`} height={30} width={30} alt='logo' />
        </div>
        <div className='flex-grow-1'>
          <h3 className='text-center'>TECH THAT HELPS OUR PLANET</h3>
        </div>
        <div>
          <button type='button' className='btn btn-transparent p-1' onClick={handleClick}>
            <img src={`${process.env.PUBLIC_URL}/images/menu-ic.svg`} height={25} width={25} alt='icon' />
          </button>
        </div>
      </div>
      <div className={`header-menu ${show ? 'show' : ''}`} onClick={handleClick}>
        <div className='l-side-m menu-wrapper'>
          <Link to={'/'} data-title="Transfer Files" className={`${active === 0 ? 'active' : ''}`}>Transfer Files</Link>
          <Link to={'/gemini'} data-title="Gemini" className={`${active === 1 ? 'active' : ''}`}>Gemini</Link>
          <Link to={'/search'} data-title="Search" className={`${active === 2 ? 'active' : ''}`}>Search</Link>
          <Link to={'/advertising'} data-title="Advertising" className={`${active === 3 ? 'active' : ''}`}>Advertising</Link>
        </div>
        <div className='r-side-m menu-wrapper'>
        <button data-title="Account" >Account</button>
          {/**!isLogin ? <button data-title="Account" onClick={onAccount}>Account</button> : <button data-title="Logout" onClick={onLogout}>Logout</button>*/}
        </div>
      </div>
    </div>
  )
}

export default Header