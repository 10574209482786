import React, { useEffect } from 'react'
import useLayout from '../hooks/useLayout'
import { Col, Row } from 'react-bootstrap'

const Advertise = () => {

  const { setLayout } = useLayout()

  useEffect(() => {
    setLayout(p => ({
      ...p,
      backgroundImages: false
    }))
    return (() => {
      setLayout(p => ({
        ...p,
        backgroundImages: true
      }))
    })
  }, [setLayout])

  return (
    <Row className='g-0 flex-column flex-lg-row'>
      <Col className='h-100 text-light position-relative  d-flex align-items-center flex-column'>
       <div className='add-tag left d-none d-lg-block'>
          <div className='add-tag-body d-flex align-items-start justify-content-start'>
         {/** <p>Protect the</p> &nbsp;
  <h3>OCEANS</h3> */}
          </div>
  </div>
        <div className='pe-lg-3 px-xl-4 mt-xl-5 px-2 text-center text-lg-end'>
          <p className='fs-4'>time to <strong>transform</strong></p>
          <p className='fs-4'>digital <strong>advertising</strong></p>
          <p className='ps-5 ms-xl-5 d-none d-lg-block'>
            Digital advertising is a trillion dollar industry and we have an innovative solution that will direct some of that to helping the planet, through every advert. This solution helps brands become more sustainable, supports good causes and enables consumers to easily help conservation.
          </p>
        </div>
      </Col>
      <Col className='h-100'>
        <div className='advertise-hero-banner'>
          <div className='advertise-hero-content'>
            <div className='hero-content-heading d-none d-lg-block'>
              <small>
                This ad is helping protect planet. 10% + of revenue towards conservation
              </small>
            </div>
            <div className='hero-content-body'>
              <img src={`${process.env.PUBLIC_URL}/images/advertise-img.png`} alt='banner' />
              <Row className='g-0 py-2 m-0 px-1 text-center d-none d-lg-flex'>
                <Col>
                  <small>
                    <strong>Planet Bikes Scores:</strong>
                  </small>
                </Col>
                <Col>
                  <small>
                    animals: 60/100
                  </small>
                </Col>
                <Col>
                  <small>
                    people: 50/100
                  </small>
                </Col>
                <Col>
                  <small>
                    planet 80/100
                  </small>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </Col>
      <Col className='h-100 position-relative'>
        <div className='d-none d-lg-block'>
          <div className='add-top-pt'>
            <div className='pt'>
              <div className='d-inline-grid'>
                <img src={`${process.env.PUBLIC_URL}/images/top-arrow.svg`} alt='arrow' />
              </div>
              <div>
                <small className='text-white'>
                  Business can decide where the ad <br /> money  revenue is spent. Marine <br /> or Rainforest conservation.
                </small>
              </div>
            </div>
          </div>
          <div className='add-mid-pt'>
            <div className='pt'>
              <div className='d-inline-grid'>
                <img src={`${process.env.PUBLIC_URL}/images/mid-arrow.svg`} alt='arrow' />
              </div>
              <div>
                <small className='text-white'>
                  The advertising interface can be <br /> adjusted, depending on the needs.
                </small>
              </div>
            </div>
          </div>
          <div className='add-bottom-pt'>
            <div className='pt'>
              <div className='d-inline-grid'>
                <img src={`${process.env.PUBLIC_URL}/images/bottom-arrow.svg`} alt='arrow' />
              </div>
              <div>
                <small className='text-white'>
                  The businesses being advertised will <br />have impact scores, to show consumers <br /> the positive impact they are having.
                </small>
              </div>
            </div>
          </div>
          <div className='add-tag right'>
            <div className='add-tag-body d-flex align-items-baseline justify-content-end'>
              {/** <p>Protect the</p> &nbsp;
<h3>RAINFORESTS</h3> */}
            </div>
  </div>
        </div>
        <div className='d-lg-none ps-5 pe-3 mt-4 text-light text-end'>
        <p>
        Digital advertising is a trillion dollar industry and we have an innovative solution that will direct some of that to helping the planet, through every advert. This solution helps brands become more sustainable, supports good causes and enables consumers to easily help conservation.
      </p>
      <p className='mb-0 fs-2 py-3'>Coming soon!</p>
        </div>
      </Col>
    </Row>
  )
}

export default Advertise