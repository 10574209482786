import React, { useEffect } from 'react'

export const Search = () => {


  useEffect(() => {
    const sc = document.createElement('script'), b = document.getElementsByTagName('body')[0];
    sc.src = "https://cse.google.com/cse.js?cx=75ece86d9f5174cc1"
    sc.async = true
    sc.onload = () => {
      // console.log('called')
      let input = document.querySelector('.gsc-search-box.gsc-search-box-tools .gsc-search-box td.gsc-input .gsc-input-box td .gsc-input')
      if (input)
        input.placeholder = 'Ask Google Anything'
    }
    b.append(sc)
    return (() => {
      let s = document.querySelectorAll('body > table')
      if (s.length > 0) {
        s.forEach(d => d.remove())
      }
      sc.remove()
    })
  })

  // const handleSearch = (e) => {
  //   e.preventDefault();
  //   const query = new FormData(e.target).get('query')
  //   console.log("searching for: ", query);
  //   // fetch(`https://my-results-page-url.com/?q=${query}`)
  //   // .then(res => res.json())
  //   // .then(res => {
  //   //   console.log(res)
  //   // })
  // }


  return (
    <div className='box search-page'>
      <div className='box-content'>
        <div className='box-content-header'>
          {/* <form className='position-relative' onSubmit={handleSearch}>
        <input className='search-input' name='query' placeholder='What would you like to search for?' />
        <Button type='submit' className='search-btn btn-black'>
            <img src={`${process.env.PUBLIC_URL}/images/search-icon.png`} width={20} height={20} alt='icon'/>
        </Button>
  </form>*/}
          <div className="gcse-searchbox" data-enableHistory="true" data-autoCompleteMaxCompletions="5"></div>
        </div>
        <div className='box-content-body'>
          <div className="gcse-searchresults" linkTarget="_blank" noResultsString="No results were found"> </div>
        </div>
      </div>
    </div>
  )
}
