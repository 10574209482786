import React from "react";

const initialState = {
    backgroundImages : true,
    themeColor :  "both",
    fixHeight : false
}

export const LayoutCtx = React.createContext();
LayoutCtx.displayName = "Layout"

const LayoutContext = ({children}) =>{

    const [layout, setLayout] = React.useState(initialState)

    return(
        <LayoutCtx.Provider value={[layout,setLayout]}>
            {children}
        </LayoutCtx.Provider>
    )
}

export default LayoutContext;