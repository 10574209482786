import React, { useEffect } from "react";
import useLayout from "../hooks/useLayout";

export const initialState = {
    isLogin : false,
    user : {
        id : null,
        email : null
    },
    token : null
}

export const AppCtx = React.createContext();
AppCtx.displayName = "App"

const AppContext = ({children}) =>{

    const [store, setStore] = React.useState(initialState)
    const { setLayout } = useLayout()
    const [showLogin,setShowLogin] = React.useState(false)
    const [connectionError,setConnectionError] = React.useState(null)

    useEffect(()=>{
        try{
            let t = window.localStorage.getItem('user')
            if(t){
                let u = JSON.parse(t)
                setStore(p=>({
                    ...p,
                    token : u[0],
                    user : u[1] ,
                    isLogin : true
                }))
                setLayout(p=>({
                    ...p,
                    themeColor : u[1].theme_type 
                }))
            }
        }catch(e){
            console.log(e)
        }
       
    },[setLayout])

  

    return(
        <AppCtx.Provider value={{store,setStore,showLogin,setShowLogin,connectionError,setConnectionError}}>
            {children}
        </AppCtx.Provider>
    )
}

export default AppContext;