import axios from 'axios'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Button } from 'react-bootstrap'
// import useLayout from '../hooks/useLayout'
import useApp from '../hooks/useApp'

function setCursorPosition(ele,position){
  let range = document.createRange();
  let sel = window.getSelection();
  range.setStart(ele.childNodes[0], position);
  range.collapse(true);
  sel.removeAllRanges();
  sel.addRange(range);
}

export const Gemini = () => {

  const [data, setData] = useState([])
  const inputRef = useRef(null)
  const formRef = useRef(null)
  const fileRef = useRef(null)
  const [inProgress, setProgress] = useState(false)
  const [isSpeaking, setIsSpeaking] = useState(false)
  const { store, setShowLogin, setConnectionError } = useApp()
  const [isFile, setIsFile] = useState(false)
  const [query, setQuery] = useState('')
  const [file, setFile] = useState(null)
  const rRef = useRef(null)
  // useLayoutEffect(()=>{
  //   setLayout(p=>({
  //     ...p,
  //     fixHeight : true
  //   }))
  //   return(()=>{
  //     setLayout(p=>({
  //       ...p,
  //       fixHeight : false
  //     }))
  //   })
  // },[])

  useEffect(() => {
    if (rRef && data.length) {
      rRef.current.scroll({ top: rRef.current.scrollHeight, behavior: 'smooth' });
    }
  }, [data])


  const handleSubmit = useCallback(async e => {
    e.preventDefault()
    // if (store.token) {
      let q = `<p class="gemini-user-input">${inputRef.current.textContent}</p>`
      setData(p => [...p, q])
      try {
        setProgress(true)
        const payload = new FormData(e.target)
        payload.append('query', inputRef.current.textContent)
        let res = await axios.post(`/ai-search/generate-ai-text-search/`, payload, {
          headers: {
            'X-Requested-With': 'XMLHttpRequest',
            // 'Authorization': `Token ${store.token}`
          }
        })
        if (res.status === 200) {
          let d = '', f = fileRef.current.files[0];
          e.target.reset()
          inputRef.current.textContent = ''
          fileRef.current.value = ''
          setQuery(null)
          setProgress(false)
          if (f) {
            d = `<div><img src="${URL.createObjectURL(f)}" /> </div>`
            setIsFile(false)
            setFile(null)
          }
          if (res.data.data !== "") {
            d += res.data.data
            setData(p => [...p, d])
          }
          e.target.reset()
        }
      } catch (e) {
        setProgress(false)
        window.recognition.stop();
        if (window.navigator.onLine) {
          setConnectionError('Something went wrong')
        } else {
          setConnectionError("No Internet Connection")
        }
      }
    // } else {
    //   setShowLogin(true)
    // }
  }, [store.token, setShowLogin, setConnectionError])

  useEffect(() => {
    if(window.webkitSpeechRecognition) {
      const recognition = new window.webkitSpeechRecognition();
    // const speechRecognitionList = new webkitSpeechGrammarList();
    // speechRecognitionList.addFromString(grammar, 1);
    // recognition.grammars = speechRecognitionList;
    recognition.continuous = false;
    recognition.lang = "en-US";
    recognition.interimResults = false;
    recognition.maxAlternatives = 1;
    recognition.onresult = (event) => {
      const t = event.results[0][0].transcript;
      inputRef.current.textContent = t
      setQuery(t)
    };
    recognition.onstart = () => {
      setIsSpeaking(true)
    };
    recognition.onspeechend = () => {
      setIsSpeaking(false)
      formRef.current.click()
    };
    recognition.onnomatch = () => {
      setIsSpeaking(false)
      inputRef.current.textContent = 'Not Recognised'
      setQuery('Not Recognised')
    };
    window.recognition = recognition
    }
  }, [])

  const handleVoice = useCallback(() => {
    // if (store.token) {
      if(window.recognition){
        window.recognition.start();
      }else{
        console.log("no voice library")
      }
    // } else {
    //   setShowLogin(true)
    // }
  }, [store.token, setShowLogin])

  const handleChange = e => {
    setQuery(e.target.innerText)
    if(e.target.innerText){
      inputRef.current.style.height = "auto"
    }else{
      inputRef.current.style.height = "60.8px"
    }
  }

  const handleInputFile = e => {
    const f = e.target.files[0]
    setIsFile(true)
    setFile(f)
    inputRef.current.focus()
  }

  const handleFileRemove = () => {
    setIsFile(false)
    setFile(null)
    fileRef.current.value = ''
  }

  const handleEnterKey = e => {
    if (e.keyCode === 13) {
      e.preventDefault()
      formRef.current.click()
    }
  }

  const handleDragOver = e => {
    e.preventDefault();
  }

  const handleDrop = e => {
    e.preventDefault();
    const fs = e.dataTransfer.files[0]
    const dataTransfer = new DataTransfer();
    dataTransfer.items.add(fs);
    const fileList = dataTransfer.files;
    fileRef.current.files = fileList;
    setFile(fs)
    setIsFile(true)
    inputRef.current.focus()
  }

  const handlePaste = e =>{
    if(e.clipboardData.files.length > 0){
      const fs =  e.clipboardData.files[0]
      const dataTransfer = new DataTransfer();
      dataTransfer.items.add(fs);
      const fileList = dataTransfer.files;
      fileRef.current.files = fileList;
      setFile(fs)
      setIsFile(true)
      inputRef.current.focus()
    }else{
      if(navigator.clipboard){
        navigator.clipboard.readText().then(data=>{
          inputRef.current.textContent = data
          setQuery(data)
          setCursorPosition(inputRef.current,data.length)
          inputRef.current.focus()
        })
      }
    }
  } 

  const handleAutoInsert = e => {
    if(e.target.classList.contains('q-pill')){
      let q = e.target.getAttribute('data-text')
      inputRef.current.textContent = q
      setQuery(q)
      setCursorPosition(inputRef.current,q.length)
      inputRef.current.focus()
    } 
  }

  return (
    <div className='box gemini-box'>
      <div className='box-content'>
       
        <div className='box-content-body position-relative overflow-hidden p-0'>
        {
          data.length === 0 ?
          <div className='initial-banner' onClick={handleAutoInsert}>
            <h2>What would you like to ask AI today?</h2>
            <div className='row align-items-md-stretch align-items-lg-center gy-3 w-100'>
                <div className='col-md-6 col-lg-12 col-xl-6'>
                  <button className='q-pill' data-text=" How can AI be used to prevent climate change?">
                  How can AI be used to prevent climate change?
                  </button>
                
                </div>
                <div className='col-md-6 col-lg-12 col-xl-6'>
                <button className='q-pill' data-text="Show me 5 YouTube videos of the Amazon Rainforest">
                Show me 5 YouTube videos of the Amazon Rainforest
                </button>
                </div>
                <div className='col-md-6 col-lg-12 col-xl-6'>
                <button className='q-pill' data-text="Write me a poem about the Galapagos">
                Write me a poem about the Galapagos
                </button>
        </div>
                <div className='col-md-6 col-lg-12 col-xl-6'>
               
                  <button className='q-pill' data-text="Show me a recipe for cooking plant-based cookies">
                  Show me a recipe for cooking plant-based cookies
                  </button>
                </div>
            </div>
          </div> 
          :
          null
        }
          <div className='mb-0 response-container h-100' ref={rRef}>
            {data.map(s => <div className='mb-0' dangerouslySetInnerHTML={{ __html: s }} />)}
          </div>
          {inProgress ? <div className='loader-overlay'>
            <div className='text-center'>
              <div className='loader light'></div>
              <p>Fetching</p>
            </div>
          </div> : null}
        </div>
        <div className='box-content-header'>
        <form className='position-relative' onSubmit={handleSubmit}>
          {/*<input className='search-input' name='query' ref={inputRef} placeholder='What would you like to ask Gemini?' />*/}
          <div className={`rich-input-wrapper ${isFile ? 'with-file' : ''}`} onDragOver={handleDragOver} onDrop={handleDrop}>
            <p name='query' ref={inputRef} onKeyDown={handleEnterKey} onPaste={handlePaste} className={`editable-input ${query ? '' : 'input-placeholder'}`} tabIndex='0' data-placeholder="Ask anything here..." aria-autocomplete='inline' aria-required={true} aria-label='input' contentEditable={true} onInput={handleChange}>
            </p>
            <div className='rich-input-media-wrapper'>
              {isFile ? <div className='rich-input-media-file-container'>
                <div className='preview-thumbnail-wrapper'>
                  <button className='preview-close-btn btn' onClick={handleFileRemove}>
                    <svg width="10" height="10" viewBox="0 0 44 55" fill="#000" xmlns="http://www.w3.org/2000/svg">
                      <path d="M32.738 54.5195L21.378 34.5995L10.578 54.5195H0.497966L16.418 26.2795L1.37797 0.119531H12.258L22.738 18.3595L32.578 0.119531H42.658L27.698 26.7595L43.618 54.5195H32.738Z" fill="#000" />
                    </svg>
                  </button>
                  <img src={URL.createObjectURL(file)} width={100} height={100} alt='query_image' />
                </div>
              </div> : null}
              <div className='rich-input-media-btns-container' onDrop={e=>e.preventDefault()}>
                <Button type='submit' ref={formRef} className='search-btn btn-black'>
                  <img src={`${process.env.PUBLIC_URL}/images/search-icon.png`} width={20} height={20} alt='icon' />
                </Button>
                <label htmlFor='file' className={`search-btn btn btn-black`} tabIndex='0'>
                  <input type="file" id='file' ref={fileRef} name='file' hidden={true} onInput={handleInputFile} />
                  <svg xmlns="http://www.w3.org/2000/svg" height="25" viewBox="0 0 24 24" width="25" fill="#fff"><path d="M0 0h24v24H0V0z" fill="none" /><path d="M18 20H4V6h9V4H4c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-9h-2v9zm-7.79-3.17l-1.96-2.36L5.5 18h11l-3.54-4.71zM20 4V1h-2v3h-3c.01.01 0 2 0 2h3v2.99c.01.01 2 0 2 0V6h3V4h-3z" /></svg>
                </label>
                <Button type='button' className={`search-btn btn-black mic-btn ${isSpeaking ? 'recording' : ''}`} onClick={handleVoice}>
                  <svg height="25" viewBox="0 0 48 48" width="25" xmlns="http://www.w3.org/2000/svg" ><path d="M24 28c3.31 0 5.98-2.69 5.98-6L30 10c0-3.32-2.68-6-6-6-3.31 0-6 2.68-6 6v12c0 3.31 2.69 6 6 6zM21.6 9.8c0-1.32 1.08-2.4 2.4-2.4 1.32 0 2.4 1.08 2.4 2.4l-.02 12.4c0 1.32-1.07 2.4-2.38 2.4-1.32 0-2.4-1.08-2.4-2.4V9.8zm13 12.2c0 6-5.07 10.2-10.6 10.2-5.52 0-10.6-4.2-10.6-10.2H10c0 6.83 5.44 12.47 12 13.44V42h4v-6.56c6.56-.97 12-6.61 12-13.44h-3.4z" /></svg>
                </Button>

              </div>
            </div>
          </div>

        </form>

      </div>
      </div>
    </div>
  )
}
