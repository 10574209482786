// import { useFileDrop } from 'easy-file-drop';
import axios from 'axios'
import React, { useCallback, useRef, useState, useEffect } from 'react'
import { Button, FormControl, FormGroup, FormLabel } from 'react-bootstrap'
import useLayout from '../hooks/useLayout'
import useApp from '../hooks/useApp'
import { themes } from '../utils/themes'
import FileThumbnail from '../components/transfer/FileThumbnail'

export const Transfer = () => {

  const { layout } = useLayout()
  const { store, setShowLogin, setConnectionError } = useApp()
  const [active, setActive] = useState('upload')
  const [error, setError] = useState(null)
  const [inProgress, setProgress] = useState(false)
  const inputRef = useRef(null)
  const [files,setFiles] = useState([])
  const thumbWrapper = useRef(null)
  const [limitSize,setLimitSize] = useState([])
  const [isDragOver,setIsDragOver] = useState(false)

  useEffect(()=>{
    function getLimitSize(){
      let size = thumbWrapper.current.clientWidth / 52
      setLimitSize(Math.floor(size) - 1)
    }
    getLimitSize()

    window.addEventListener("resize",getLimitSize)
    return (()=>{
      window.removeEventListener("resize",getLimitSize)
    })

  },[])

  const validate = useCallback(payload => {
    let err = null
    for (const [key, value] of payload.entries()) {
      if (key === 'sender_email') {
        if (!value) {
          err = 'Plase enter a sender email address!'
          break;
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
          err = 'Plase enter a valid email address!'
          break;
        }
      } else if (key === 'receiver_email') {
        if (!value) {
          err = 'Plase enter a receiver email address!'
          break;
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
          err = 'Plase enter a valid email address!'
          break;
        }
      }
      // else if (key === 'file') {
      //   if (!value.name && value.size === 0) {
      //     err = 'Plase select a file to send!'
      //     break;
      //   }
      // else if(value.type !== 'application/x-zip-compressed'){
      //   err = 'Please select only zip file'
      //   break;
      // }
      // }
    }
    if (!err && files.length === 0) {
      err = 'Plase select a file to send!'
    }
    setError(err)
    return err ? false : true
  }, [files])

  const handleSubmit = useCallback(async e => {
    e.preventDefault()
    // if (store.token) {
      const payload = new FormData(e.target)
      const f = [...files]
      for (let i = 0; i < f.length; i++) {
        payload.append(`files_${i + 1}`, f[i])
      }
      payload.append('files_count', f.length)

      if (validate(payload)) {
        setProgress(true)
        try {
          let res = await axios.post(`/file-transfer/upload/`, payload, {
            headers: {
              'X-Requested-With': 'XMLHttpRequest',
              // 'Authorization': `Token ${store.token}`
            }
          })
          if (res.status === 201) {
            setProgress(false)
            setFiles([])
            setActive('success')
            e.target.reset()
          }
        } catch (e) {
          const { response } = e
          if (response) {
            setError(response.data.error)
            setProgress(false)
          } else {
            if (window.navigator.onLine) {
              setConnectionError('Something went wrong')
            } else {
              setConnectionError("No Internet Connection")
            }
          }
        }
      }
    // } else {
    //   setShowLogin(true)
    // }
  }, [store.token, validate, setShowLogin, setConnectionError,files])

  const handleReset = () => {
    setActive('upload')
  }

  const handleFileSelect = e =>{
    const fs = e.target.files
    setFiles(p=>[...p,...fs])
    // e.target.value = ''
  }

  const handleRemove = f =>{
    setFiles(p=>p.filter(file => file !== f))
  }

  // const onFiles = useCallback(async (files) => {
  //   for (let index = 0; index < files.length; index++) {
  //     const file = files[index];
  //     console.log(file)
  //   }
  // }, []);
  // const { element, dragging } = useFileDrop(onFiles);

  const handleDragOver = e =>{
    e.preventDefault();
    setIsDragOver(true)
  }
  const handleDragLeave = e =>{
    e.preventDefault();
    setIsDragOver(false)
  }

  const handleDrop = e =>{
    e.preventDefault();
    const fs =  e.dataTransfer.files
    setFiles(p=>[...p,...fs])
    setIsDragOver(false)
  }

  return (
    <div className='transfer-page-wrapper d-flex flex-column-reverse flex-lg-row h-100'>
      <div className='l-side h-100 mt-5 mt-lg-0'>
        <div className='send-box rounded-4 overflow-hidden text-dark h-100 pt-4 pt-lg-3'>
          {active === 'upload' ? <form className='d-flex flex-column h-100' onSubmit={handleSubmit}>
            <div className='px-4 px-lg-2 px-xl-3'>
              <p>Send 📁 to anyone on 🌎 using 📱💻</p>
              <hr />
            </div>
            <div className='flex-grow-1 px-4 px-lg-2 px-xl-3 d-flex flex-column'>
              <FormGroup className='mb-3'>
                <FormLabel>Your email address</FormLabel>
                <FormControl type='email' name='sender_email' placeholder='Email Address' />
              </FormGroup>
              <FormGroup>
                <FormLabel>Recipients email address</FormLabel>
                <FormControl type='email' name='receiver_email' placeholder='Email Address' />
              </FormGroup>
              <div className='flex-grow-1'>
                <div className='d-flex h-100 flex-column'>
                  <div className='d-flex align-items-center flex-grow-1 w-100 justify-content-center my-1'>
                    <FormControl type='file' id='file' hidden={true} multiple={true} ref={inputRef} onInput={handleFileSelect} />
                    <label htmlFor='file' className={`d-flex align-items-center w-100 justify-content-center h-100 file-upload ${isDragOver ? 'isFileDarg' : '' }`} onDrop={handleDrop} onDragLeave={handleDragLeave} onDragOver={handleDragOver}>
                      <div className='text-center' style={{pointerEvents:'none'}}>
                        <svg width="40" height="40" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <g clip-path="url(#clip0_7_826)">
                            <g opacity="0.5" filter="url(#filter0_d_7_826)">
                              <path d="M29 42C37.2843 42 44 35.2843 44 27C44 22.8678 42.3291 19.1258 39.6261 16.4129L32 16V14L24 6L16 13.9551V14.9946L8 14.5143C5.55699 16.5319 4 19.5841 4 23C4 29.0751 8.92487 34 15 34C15.2412 34 15.4806 33.9922 15.7179 33.9769C18.2289 38.7473 23.2346 42 29 42Z" fill={themes[layout.themeColor]} />
                            </g>
                            <path d="M32 14L24 6M24 6L16 14M24 6V24M8 14.5143C5.55699 16.5319 4 19.5841 4 23C4 29.0751 8.92487 34 15 34C15.437 34 15.8459 34.228 16.0678 34.6045C18.6759 39.0303 23.4911 42 29 42C37.2843 42 44 35.2843 44 27C44 22.8678 42.3291 19.1258 39.6261 16.4129" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                          </g>
                          <defs>
                            <filter id="filter0_d_7_826" x="0" y="6" width="48" height="44" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                              <feFlood flood-opacity="0" result="BackgroundImageFix" />
                              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                              <feOffset dy="4" />
                              <feGaussianBlur stdDeviation="2" />
                              <feComposite in2="hardAlpha" operator="out" />
                              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_7_826" />
                              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_7_826" result="shape" />
                            </filter>
                            <clipPath id="clip0_7_826">
                              <rect width="48" height="48" fill="white" transform="matrix(-1 0 0 -1 48 48)" />
                            </clipPath>
                          </defs>
                        </svg>
                        <p className='m-0'><strong>{!isDragOver ? 'Choose files or drag and drop' : 'Drop files Here'}</strong></p>
                        {/*<small className='m-0'>Zip</small>*/}
                      </div>
                    </label>
                  </div>
                  <div>
                    <div className='thumbnails-wrapper' ref={thumbWrapper}>
                      {
                        files.slice(0,limitSize).map((f) => <FileThumbnail file={f} theme={themes[layout.themeColor]} onRemove={handleRemove} key={f.name} />)
                      }
                      {
                        files.length > limitSize ?
                        <div className='more-files-thumbnail'>
                          <strong>+{files.length - limitSize}<br/> files</strong>
                        </div> : null
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className={`m-0 bg-danger text-white px-3 py-1 d-flex align-items-center transfer-error ${error ? 'd-block' : 'd-none'}`}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" stroke='#fff' fill="#fff" className="bi bi-exclamation-circle" viewBox="0 0 16 16">
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                  <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0M7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0z" />
                </svg>
                <span className='text-white ms-2'>{error}</span>
              </div>
              {/*<div className={`m-0 bg-dark text-white px-3 py-1 d-flex align-items-center transfer-error ${inProgress ? 'd-block' : 'd-none'}`}>
                <div className='loader'></div>
                <span className='text-white ms-2'>Please wait file is uploading!</span>
  </div>*/}
              <Button type='submit' className='btn-black w-100 rounded-0'>
                Send
              </Button>
            </div>
          </form> :
            <div className='d-flex align-items-center' style={{ minHeight: '386px' }}>
              <div className='text-center w-100' >
                <p>Congratulations, your files were sent.</p>
                <div className='mt-5'>
                  <Button type='button' className='btn-black' onClick={handleReset}>
                    Send more
                  </Button>
                </div>
              </div>
            </div>}
          {inProgress ? <div className='loader-overlay'>
            <div className='text-center'>
              <div className='loader dark'></div>
              <p>Processing</p>
            </div>
          </div> : null}
        </div>
        <div className='text-center mt-5 d-lg-none'>
          <small className='note'>Allowing you to support marine or ocean conservation by using our <br /> simple tools that improve your life. Choose what you want to protect:</small>
        </div>
      </div>
      <div className='r-side px-lg-2 px-xl-4 h-100'>
        <div className='h-100 pt-0 pt-lg-5 mt-0 mt-lg-4'>
          <h3 className='d-none d-lg-block mb-5'>TECH THAT HELPS OUR PLANET 🌎</h3>
          <p>
            It’s simple. Choose what you want to protect,  blue for oceans or green for rainforests.
            Use the tools and once we start to generate revenue, we’ll give at least 10% to protect nature. We have a big vision to utilise AI & want to revolutionise digital ads.
          </p>
        </div>
      </div>
    </div>
  )
}

